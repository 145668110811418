import React from 'react';
import _ from "lodash";
import SiteConfig from "../../config/SiteConfig.json";
import { isMobile, isIOS, isAndroid } from 'react-device-detect';
let showdown  = require('showdown')
let converter = new showdown.Converter()

//THIS COMPONENT GET THE PLATFORMS (DESKTOP, IOS, ANDROID) AND SHOW THE CONTENT OF EVERY PLATFORM
class PlatformsTabs extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            activeIndex: '',
            selectedPlatform: []
       };
    }

    componentDidMount(){//INIT THE PLATFORMS TABS
        let { platforms, postHTML } = this.props

        let windows = platforms && platforms.windows && platforms.windows.replace(/src = "\/ assets \/.*?"|src="\/assets\/.*?"/g, function(match, token) {
            return match.replace(/\s/g, '');
        });
        // let mac = platforms && platforms.mac && platforms.mac.replace(/src = "\/ assets \/.*?"|src="\/assets\/.*?"/g, function(match, token) {
        //     return match.replace(/\s/g, '');
        // });
        // let linux = platforms && platforms.linux && platforms.linux.replace(/src = "\/ assets \/.*?"|src="\/assets\/.*?"/g, function(match, token) {
        //     return match.replace(/\s/g, '');
        // });
        let ios = platforms && platforms.ios && platforms.ios.replace(/src = "\/ assets \/.*?"|src="\/assets\/.*?"/g, function(match, token) {
            return match.replace(/\s/g, '');
        });
        let android = platforms && platforms.android && platforms.android.replace(/src = "\/ assets \/.*?"|src="\/assets\/.*?"/g, function(match, token) {
            return match.replace(/\s/g, '');
        });

        //USING USER AGENT TO DETECT DEVICE AND NAVIGATE TO MATCH PLATFORM
        if(!isMobile){

            if(platforms.windows){
                this.createPlatformNav('Desktop', windows)
            }
            else if(!platforms.iswindows && platforms.isandroid){
                if(platforms.android){
                    this.createPlatformNav('Android', android)
                }else{
                    this.createPlatformNav('Android', postHTML)
                }
            }else if(!platforms.iswindows && !platforms.isandroid && platforms.isios){
                if(platforms.ios){
                    this.createPlatformNav('Ios', ios)
                }else{
                    if(platforms.isandroid && platforms.android){
                        this.createPlatformNav('Ios', platforms.android)
                    }else{
                        this.createPlatformNav('Ios', postHTML)
                    }
                }
            }else{
                this.createPlatformNav('Desktop', postHTML)
            }
        }

        if(isMobile){
            if(isIOS && platforms.isios){
                if(platforms.ios){
                    this.createPlatformNav('Ios', platforms.ios)
                }else{
                    if(platforms.isandroid && platforms.android){
                        this.createPlatformNav('Ios', platforms.android)
                    }else{
                        this.createPlatformNav('Ios', postHTML)
                    }
                }
            }else if(isIOS && platforms.isandroid){
                if(platforms.android){
                    this.createPlatformNav('Android', android)
                }else{
                    this.createPlatformNav('Android', postHTML)
                }
            }else if(isIOS && !platforms.isandroid && !platforms.isios && platforms.iswindows){
                if(platforms.windows){
                    this.createPlatformNav('Desktop', windows)
                }else{
                    this.createPlatformNav('Desktop', postHTML)
                }
            }

            if(isAndroid && platforms.isandroid){
                if(platforms.android){
                    this.createPlatformNav('Android', android)
                }else{
                    this.createPlatformNav('Android', postHTML)
                }
            }else if(isAndroid && platforms.isios){
                if(platforms.ios){
                    this.createPlatformNav('Ios', platforms.ios)
                }else{
                    if(platforms.isandroid && platforms.android){
                        this.createPlatformNav('Ios', platforms.android)
                    }else{
                        this.createPlatformNav('Ios', postHTML)
                    }
                }
            }else if(isAndroid && !platforms.isandroid && !platforms.isios && platforms.iswindows){
                if(platforms.windows){
                    this.createPlatformNav('Desktop', windows)
                }else{
                    this.createPlatformNav('Desktop', postHTML)
                }
            }
        }
    }
    
    createPlatformNav = (platformName, platformContent) => {
        return (this.setState({activeIndex: platformName}), this.setState({selectedPlatform: platformContent }))
    }

    render(){
        
        //src\s*=\s*"(.+?)"
        let { platforms, postHTML, generaltext, lang } = this.props
        let windows = platforms && platforms.windows && platforms.windows.replace(/src = "\/ assets \/.*?"|src="\/assets\/.*?"/g, function(match, token) {
            return match.replace(/\s/g, '');
        });
        // let mac = platforms && platforms.mac && platforms.mac.replace(/src = "\/ assets \/.*?"|src="\/assets\/.*?"/g, function(match, token) {
        //     return match.replace(/\s/g, '');
        // });
        // let linux = platforms && platforms.linux && platforms.linux.replace(/src = "\/ assets \/.*?"|src="\/assets\/.*?"/g, function(match, token) {
        //     return match.replace(/\s/g, '');
        // });
        let android = platforms && platforms.android && platforms.android.replace(/src = "\/ assets \/.*?"|src="\/assets\/.*?"/g, function(match, token) {
            return match.replace(/\s/g, '');
        });
        let ios = platforms && platforms.ios && platforms.ios.replace(/src = "\/ assets \/.*?"|src="\/assets\/.*?"/g, function(match, token) {
            return match.replace(/\s/g, '');
        });

        return (
            <div>
                <div id="tip-content" style={{marginTop: '25px'}} dangerouslySetInnerHTML={{ __html: lang === 'en' ? converter.makeHtml(generaltext) : generaltext }}></div>
                <div className="nav">
                    {platforms.iswindows && <div className={this.state.activeIndex === 'Desktop' ? "nav-link active" : "nav-link "} onClick={() => {this.setState({selectedPlatform: platforms.windows ? windows : postHTML, activeIndex: 'Desktop'})}}>Desktop</div>}
                    {/* {platforms.ismac && <div className={this.state.activeIndex === 'Mac OS' ? "nav-link active" : "nav-link "} onClick={() => {this.setState({selectedPlatform: platforms.mac ? mac : platforms.iswindows && platforms.windows ? platforms.windows : postHTML, activeIndex: 'Mac OS'})}}>Mac OS</div>} */}
                    {/* {platforms.islinux && <div className={this.state.activeIndex === 'Linux' ? "nav-link active" : "nav-link "} onClick={() => {this.setState({selectedPlatform: platforms.linux ? linux : platforms.iswindows && platforms.windows ? platforms.windows : postHTML, activeIndex: 'Linux'})}}>Linux</div>} */}
                    
                    {platforms.isandroid && <div className={this.state.activeIndex === 'Android' ? "nav-link active" : "nav-link "} onClick={() => {this.setState({selectedPlatform: platforms.android ? android : postHTML, activeIndex: 'Android'})}}>Android</div>}
                    {platforms.isios && <div className={this.state.activeIndex === 'Ios' ? "nav-link active" : "nav-link "} onClick={() => {this.setState({selectedPlatform: platforms.ios ? ios : platforms.isandroid && platforms.android ? platforms.android : postHTML, activeIndex: 'Ios'})}}>iOS</div>}
                </div>
                <div>
                {this.state.selectedPlatform !== null && this.state.selectedPlatform !== undefined ?
                    <div className="mt-4" dangerouslySetInnerHTML={{ __html: this.state.selectedPlatform}}/>
                    :   //IF THERE IS NO CONTENT TO PLATFORM - TAKES THE DEFAULT TEXT OF BEFORE
                    <div className="mt-4" id="tip-content" dangerouslySetInnerHTML={{ __html: _.replace(postHTML, /@brand/g, SiteConfig.brand)}}/>
                    } 
                </div>
            </div>
        );
    }
} 
export default PlatformsTabs;