import React from "react";
import Header from "../components/header";
import Seo from "../components/SEO";
import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import _ from "lodash";
import { navigate } from "gatsby";
import Layout from "../components/layout";
import { Location } from "@reach/router";
import SiteConfig from "../../config/SiteConfig.json";
import PlatformsTabs from "../components/platforms-tabs";
let showdown = require("showdown");
let converter = new showdown.Converter();

export default class PostTamplate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: "en",
      text: "See Also",
      imageClassName: ""
    };
  }

  UNSAFE_componentWillMount() {
    let location = this.props.location.pathname;
    let {
      uri,
      pageContext: { lang }
    } = this.props;
    if (location.includes("/es/")) {
      this.setState({ lang: "es", text: "Ver también" });
    } else if (location.includes("/ru/")) {
      this.setState({ lang: "ru", text: "Смотрите также" });
    } else if (location.includes("/de/")) {
      this.setState({ lang: "de", text: "Siehe auch" });
    } else if (location.includes("/pt/")) {
      this.setState({ lang: "pt", text: "Veja também" });
    } else if (location.includes("/ja/")) {
      this.setState({ lang: "ja", text: "参照してください" });
    } else if (location.includes("/cs/")) {
      this.setState({ lang: "cs", text: "Viz též" });
    } else if (location.includes("/fr/")) {
      this.setState({ lang: "fr", text: "Voir également" });
    } else if (location.includes("/it/")) {
      this.setState({ lang: "it", text: "Guarda anche" });
    } else if (location.includes("/el/")) {
      this.setState({ lang: "el", text: "Δείτε επίσης" });
    } else if (location.includes("/fi/")) {
      this.setState({ lang: "fi", text: "Katso myös" });
    } else if (location.includes("/hi/")) {
      this.setState({ lang: "hi", text: "यह भी देखें" });
    } else if (location.includes("/hr/")) {
      this.setState({ lang: "hr", text: "Vidi također" });
    } else if (location.includes("/hu/")) {
      this.setState({ lang: "hu", text: "Lásd még" });
    } else if (location.includes("/id/")) {
      this.setState({ lang: "id", text: "Lihat juga" });
    } else if (location.includes("/ko/")) {
      this.setState({ lang: "ko", text: "참고 사항" });
    } else if (location.includes("/nb/")) {
      this.setState({ lang: "nb", text: "Se også" });
    } else if (location.includes("/nl/")) {
      this.setState({ lang: "nl", text: "Zie ook" });
    } else if (location.includes("/pl/")) {
      this.setState({ lang: "pl", text: "Zobacz też" });
    } else if (location.includes("/tr/")) {
      this.setState({ lang: "tr", text: "Ayrıca bakınız" });
    } else if (location.includes("/he/")) {
      this.setState({ lang: "he", text: "ראה גם" });
    } else if (location.includes("/ar/")) {
      this.setState({ lang: "ar", text: "أنظر أيضا" });
    } else if (location.includes("/zh/")) {
      this.setState({ lang: "zh", text: "也可以看看" });
    }
    if (lang === "en") {
      this.setState({ imageClassName: uri.replace("/", "") });
    } else {
      this.setState({ imageClassName: uri.replace(/\/.*?\//, "") });
    }
  }

  render() {
    let postHTML = this.props.pageContext.html;
    let generaltext = this.props.pageContext.generaltext;
    let platforms = this.props.pageContext.platforms;
    let bottomgeneraltext = this.props.pageContext.bottomgeneraltext;
    let title = this.props.pageContext.title;
    let pathname = this.props.location.pathname.replace("/", "").charAt(0);
    let bmPostOGImg;
    let { imageClassName } = this.state;

    // var n,res;
    let sidebar = this.props.pageContext.sidebar;
    sidebar = _.sortBy(sidebar, "date").reverse();

    if (this.state.lang === "en") {
      platforms.windows = converter.makeHtml(platforms.windows);
      platforms.mac = converter.makeHtml(platforms.mac);
      platforms.linux = converter.makeHtml(platforms.linux);
      platforms.android = converter.makeHtml(platforms.android);
      platforms.ios = converter.makeHtml(platforms.ios);
    }

    if (postHTML !== null && this.state.lang !== "en") {
      postHTML = _.replace(
        postHTML,
        /href="\//g,
        `href="/${this.state.lang.toLowerCase()}/`
      );
    }
    if (SiteConfig.brand === "BlueMail") {
      if (pathname.match(/[a-m]/)) {
        bmPostOGImg = "/assets/BlueMail-Help-Center-OG_1_1.png";
      } else if (pathname.match(/[n-z]/)) {
        bmPostOGImg = "/assets/BlueMail-Help-Center-OG_1_2.png";
      }
    }

    return (
      <Location>
        {({ location }) => (
          <Layout lang={this.state.lang}>
            <Seo
              postTitle={`${title} - ${SiteConfig.seo.siteName}`}
              postURL={this.props.location.pathname}
              postLanguage={this.state.lang}
              postDescription={SiteConfig.seo.siteDescription}
              postImage={bmPostOGImg}
            />
            <Header lang={this.state.lang} />
            <Container className="tip-container" style={{ paddingTop: "0" }}>
              <Row
                className="post-container"
                style={{
                  maxWidth: "100%",
                  margin: "auto",
                  borderRadius: SiteConfig.content.post.containerRadius,
                  backgroundColor: SiteConfig.content.post.backgroundColor,
                  boxShadow: SiteConfig.content.post.boxShadow
                }}
              >
                {/* the post */}
                <Col lg={8} xs={12} id="tip-post" className="borderRight">
                  <div className="tip-post-padding">
                    {imageClassName && <div className={imageClassName}>
                      {//IF WE HAVE AT LEAST ONE PLATFORM TAB THAT IS TRUE -> DISPLAY PLATFORMS TAB
                      platforms.iswindows ||
                      platforms.ismac ||
                      platforms.islinux ||
                      platforms.isandroid ||
                      platforms.isios ? (
                        <div>
                          <h2 id="tip-header">
                            {_.replace(title, /@brand/g, SiteConfig.brand)}
                          </h2>
                          <PlatformsTabs
                            platforms={platforms}
                            generaltext={generaltext}
                            postHTML={postHTML}
                            lang={this.state.lang}
                          />
                          {bottomgeneraltext && (
                            <div
                              style={{ marginTop: "30px" }}
                              dangerouslySetInnerHTML={{
                                __html:
                                  this.state.lang === "en"
                                    ? converter.makeHtml(bottomgeneraltext)
                                    : bottomgeneraltext
                              }}
                            ></div>
                          )}
                        </div>
                      ) : (
                        <div>
                          <h2 id="tip-header">
                            {_.replace(title, /@brand/g, SiteConfig.brand)}
                          </h2>
                          <div
                            style={{ marginTop: "30px" }}
                            className={SiteConfig.brand}
                            id="tip-content"
                            dangerouslySetInnerHTML={{
                              __html: _.replace(
                                postHTML,
                                /@brand/g,
                                SiteConfig.brand
                              )
                            }}
                          />
                        </div>
                      )}
                    </div>}
                  </div>
                </Col>
                <Col lg={4} xs={12}>
                  <ListGroup className="py-5">
                    <h4 className="ml-3" style={{ fontSize: "20px" }}>
                      {this.state.text ? this.state.text : "See Also"}
                    </h4>
                    {sidebar &&
                      sidebar.map((post, i) => {
                        if (i < 8 && post.title !== title) {
                          return (
                            <ListGroupItem
                              onMouseOver={this.jshover}
                              style={{ fontSize: "15px" }}
                              key={i}
                              className="d-flex sidebar-item border-0 font-weight-bold"
                              onClick={() => navigate(post.meta)}
                            >
                              <span className="arrow-icon">&#187;</span>
                              {_.replace(
                                post.title,
                                /@brand/g,
                                SiteConfig.brand
                              )}
                            </ListGroupItem>
                          );
                        }
                        return "";
                      })}
                  </ListGroup>
                </Col>
              </Row>
            </Container>
          </Layout>
        )}
      </Location>
    );
  }
}
